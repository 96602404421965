import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { FBaseService, ResponseEnvelope } from '@kasasa/fbase-components/lib';
import { SuppressionList, SuppressionStatus } from './types';

const DEFAULT_TIMEOUT = 30000;
const TIMEOUT_ERROR_MESSAGE = 'Please check your network connection and try again';

const defaultConfig: AxiosRequestConfig = {
	timeout: DEFAULT_TIMEOUT,
	timeoutErrorMessage: TIMEOUT_ERROR_MESSAGE
};

export default class SuppressionListInfoService extends FBaseService {
	getSuppressionList(fiId: string): Promise<AxiosResponse<ResponseEnvelope<SuppressionList>>> {
		return this.apiClient.get(`/api/v1/marketing-cloud/do-not-email/${fiId}/list`, defaultConfig);
	}

	getSuppressionStatus(fiId: string): Promise<AxiosResponse<ResponseEnvelope<SuppressionStatus>>> {
		return this.apiClient.get(`/api/v1/marketing-cloud/do-not-email/${fiId}/status`, defaultConfig);
	}

	sendSuppressionList(fiId: string, emails: string[]): Promise<AxiosResponse<ResponseEnvelope<number>>> {
		// will need to look into the body here. Not sure I have specified this right
		return this.apiClient.post(`/api/v1/marketing-cloud/do-not-email/${fiId}`, { emails }, defaultConfig);
	}

}
