import Papa from "papaparse";

export const parseEmailListFile = (file: File): Promise<string[]> => {
	return new Promise((resolve, reject) => {
		Papa.parse<string[]>(file, {
			complete: ({ data }) => {
				const rawEmails = (data ?? [])
					.flat()
					.map(ent => ent.trim())
					.filter(Boolean);
				resolve(rawEmails);
			},
			error: reject
		});
	});
};

export const suppresionReportHeaderFields = ["Username", "Suppression Type", "Created date time"];

export const createCsvFile = (filename: string, dataSource: string[][]) => {
	const csvContent = `data:text/csv;charset=utf-8,${dataSource.map(cells => cells.join(',')).join('\n')}`;
	const encodedUri = encodeURI(csvContent);
	const link = document.createElement("a");
	link.href = encodedUri;
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();
};

export const formatDateTime = (date: Date | number | string) => {
	return new Intl.DateTimeFormat('en-US', {
		year: 'numeric', month: 'numeric', day: 'numeric',
		hour: 'numeric', minute: 'numeric', second: 'numeric'
	}).format(new Date(date)).replace(', ', ' at ');
};
