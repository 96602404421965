































































































































































































import validator from "validator";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { EmailEntry, SuppressionStatus } from "@/services/types";
import SuppressionListInfoService from "@/services/SuppressionListInfoService";
import { createCsvFile, parseEmailListFile, suppresionReportHeaderFields, formatDateTime } from "../helpers";
import { Alert, NoticeClass } from '@kasasa/fbase-components/lib';

@Component
export default class DoNotEmail extends Vue {
	suppressionListInfoSvc = new SuppressionListInfoService(this.$store);

	isSuppressionListLoading = false;
	suppressionListInfo = {} as SuppressionStatus;

	isSending = false;
	goodEmails: string[] = [];
	rejectedEmails: string[] = [];
	search = '';
	suppressionList: EmailEntry[] = [];
	suppressionListHeaders = [
		{ text: 'Username', value: 'emailAddress' },
		{ text: 'Suppression type', value: 'type' },
		{ text: 'Created date time', value: 'createdDatetime' },
	];
	reviewDialog = false;
	reviewDialogTitle = 'Good Emails';
	reviewEmails: string[] = [];
	formatDateTime = formatDateTime;

	@Watch("$route", { immediate: true, deep: true })
	async loadData() {
		try {
			this.isSuppressionListLoading = true;
			const [suppressionListResponse, suppresionStatusResponse] = await Promise.all([
				this.suppressionListInfoSvc.getSuppressionList(this.$route.meta.fi.fiId),
				this.suppressionListInfoSvc.getSuppressionStatus(this.$route.meta.fi.fiId)
			]);
			this.suppressionList = suppressionListResponse.data.data;
			this.suppressionListInfo = suppresionStatusResponse.data.data;
		} catch (error) {
			console.warn('getSuppressionStatus error:', error);
			this.showAlert(error.message);
		} finally {
			this.isSuppressionListLoading = false;
		}
	}

	async loadFile(fileList: FileList) {
		try {
			if (fileList.length === 0) {
				return;
			}
			// parse emails list file
			const rawEmails = await parseEmailListFile(fileList[0]);
			this.goodEmails = rawEmails.filter(item => validator.isEmail(item));
			this.rejectedEmails = rawEmails.filter(item => !validator.isEmail(item));
		} catch (error) {
			console.warn(error);
			this.showAlert(error.message);
		}
	}

	async sendEmails() {
		try {
			if (this.goodEmails.length > 0) {
				this.isSending = true;
				await this.suppressionListInfoSvc.sendSuppressionList(
					this.$route.meta.fi.fiId,
					this.goodEmails
				);
				// refresh the suppression status
				this.loadData();
			}
		} catch (error) {
			console.warn(error);
			this.showAlert(error.message);
		} finally {
			this.isSending = false;
		}
	}

	downloadReport() {
		const rows = this.suppressionList.map(({ emailAddress, type, createdDatetime }) => [
			emailAddress,
			type,
			formatDateTime(createdDatetime)
		]);
		rows.unshift(suppresionReportHeaderFields);
		const filename = `${this.$route.meta.fi.fiId}_unsubscribed_emails_${Date.now()}.csv`;
		createCsvFile(filename, rows);
	}

	showGoodEmails() {
		this.reviewDialogTitle = 'Good Emails';
		this.reviewEmails = this.goodEmails;
		this.reviewDialog = true;
	}

	showRejectedEmails() {
		this.reviewDialogTitle = 'Rejected Emails';
		this.reviewEmails = this.rejectedEmails;
		this.reviewDialog = true;
	}

	showAlert(message: string, alertType = NoticeClass.ERROR) {
		this.$store.dispatch('notices/add', new Alert(message, alertType));
	}
}
