import '@/hooks';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import '@/plugins/axios';
import { appModeCheck, fiLockDown } from '@kasasa/fbase-components/lib';

Vue.config.productionTip = false;

// use following if you want /:clientId lockdown
router.beforeEach(fiLockDown(store));

// assumes fbase-portalapp proxy of /api/session/portalapp-status
// router.beforeEach(appModeCheck(store));

// import { GlobalWorkspacePlugin } from '@kasasa/fbase-components/lib';
// Vue.use(GlobalWorkspacePlugin, {store});

// store.dispatch('auth/initializePortalSession', store);

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app');
