import Vue from 'vue';
import Vuex from 'vuex';

import { AuthStore, NoticeStore, pendoPlugin } from '@kasasa/fbase-components/lib';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		fiId: null,
		isFiUser: false,
	},
	mutations: {
		fiId(state, fiId) {
			state.fiId = fiId;
		},
		isFiUser(state, isFiUser) {
			state.isFiUser = isFiUser;
		},
	},
	actions: {
	},
	modules: {
		auth: AuthStore,
		notices: NoticeStore
	},
	plugins: [pendoPlugin]
});
