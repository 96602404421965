
























import Vue from "vue";
import { mapState } from "vuex";
import { namespace } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";
import menuDefinitions from "@/menu";
import {
	AuthGroupManager,
	MenuItem,
	MenuHeading,
	AuthStore,
} from "@kasasa/fbase-components/lib";
import {
	KPortalHeader,
	KAppToolbar,
	KNoticeContainer,
	KAppToolbarBtn,
	KNavigationDrawer,
} from "@kasasa/fbase-components";

const auth = namespace("auth");

@Component({
	name: "App",
	components: {
		KPortalHeader,
		KAppToolbar,
		KNoticeContainer,
		KAppToolbarBtn,
		KNavigationDrawer,
	},
	computed: {
		...mapState(["auth"]),
	},
})
export default class App extends Vue {
	@auth.State("authManager") authManager!: AuthGroupManager;

	auth!: AuthStore;

	drawer = true;

	@Prop({
		type: Array,
		default() {
			return [...menuDefinitions];
		},
	})
	menuItems!: (MenuItem | MenuHeading)[];

	
}
